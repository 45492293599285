import { ChangeDetectorRef, Component } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../../libs/kfp/src/lib/app-service/kfp.service';
import * as _moment from 'moment';

const moment = _moment;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(translate: TranslateService, appService: AppService, changeDetectorRef: ChangeDetectorRef) {
        translate.addLangs(['cz']);
        translate.setDefaultLang('cz');
        translate.use('cz');
        if (localStorage.getItem('color')) {
            appService.changePrimaryColor(localStorage.getItem('color'));
            changeDetectorRef.markForCheck();
        } else {
            appService.changePrimaryColor('#e84e0e');
            changeDetectorRef.markForCheck();
        }

        if (localStorage.getItem('color2')) {
            appService.changePrimary2Color(localStorage.getItem('color2'));
            changeDetectorRef.markForCheck();
        } else {
            appService.changePrimary2Color('#002b4f');
            changeDetectorRef.markForCheck();
        }
        this.setMomentLocalization();

        translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.setMomentLocalization(event.lang);
        });

        // https://github.com/moment/moment/issues/6153
    }

    setMomentLocalization(lang = 'cz'): void {
        moment.locale(lang === 'cz' ? 'cs' : lang);
        const upMonths = moment.months().map((m) => {
            return m.charAt(0).toUpperCase() + m.slice(1);
        });
        moment.updateLocale(lang === 'cz' ? 'cs' : lang, {
            months: upMonths,
        });
    }
}

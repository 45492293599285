import {Component, OnInit } from '@angular/core';

@Component({
    selector: 'fuse-splash-screen',
    template: `
        <img [src]="logoPath" alt="Logo" />
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
  `,
})
export class SplashScreenComponent implements OnInit {
    logoPath: string;

    ngOnInit(): void {

        // Check localStorage for the logo path, or use default
        const storedLogo = localStorage.getItem('splashLogo');
        this.logoPath = storedLogo ? storedLogo : 'assets/images/logo/logo-180-white.png';
    }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {
    ApexAnnotations,
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexLegend,
    ApexMarkers,
    ApexPlotOptions,
    ApexResponsive,
    ApexStroke,
    ApexTitleSubtitle,
    ApexTooltip,
    ApexXAxis,
    ApexYAxis,
} from 'ng-apexcharts';
import {combineLatest, map, Observable, switchMap} from 'rxjs';
import {MortgageService, SeriesLabel} from './mortgage.service';
import {CreateRealEstateDataRequest} from 'kfp';
import {MortgageChart} from './charts/mortgage-chart';

export type MortgagesChartType = 'MORTGAGE' | 'INVEST' | 'BONITA';
export type MortgagesChartOptions = {
    params?: any;
};

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis?: ApexXAxis;
    stroke?: ApexStroke;
    dataLabels?: ApexDataLabels;
    yaxis?: ApexYAxis;
    title?: ApexTitleSubtitle;
    labels?: string[];
    legend?: ApexLegend;
    subtitle?: ApexTitleSubtitle;
    plotOptions?: ApexPlotOptions;
    colors: string[];
    tooltip: ApexTooltip;
    fill?: ApexFill;
    markers?: ApexMarkers;
    responsive?: ApexResponsive;
    annotations?: ApexAnnotations;
};

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class MortgageChartService {

    constructor(
        public translateService: TranslateService,
        private _httpClient: HttpClient,
        private _mortgagesService: MortgageService,
        private readonly _mortgageChart: MortgageChart,

    ) {
    }

    public chartByType(type: MortgagesChartType, parameters: any, options?: any) {
        switch (type) {
            case 'MORTGAGE':
                return [this.dataMortgageChart(parameters)];
            case 'INVEST':
                return [this.dataMortgageChart(parameters)];
            case 'BONITA':
                return [this.dataMortgageChart(parameters)];
        }
    }

    public dataMortgageChart(parameters: any): Observable<ChartOptions> {
        return this._mortgagesService.dataMortgageChart(parameters).pipe(
            untilDestroyed(this),
            switchMap((res) => {
                this._mortgageChart.updateSeries(res);
                return this._mortgageChart.getChartData();
            })
        );
    }

    public getAllMortgageChart(parameters: any): Observable<ChartOptions[]> {
        return this._mortgagesService.dataMortgageChart(parameters).pipe(
            untilDestroyed(this),
            switchMap((res: SeriesLabel[]) => {
                return this._mortgageChart.getAllChartsData(res);
            })
        );
    }

    prepareDataForPdfExport(parameters: CreateRealEstateDataRequest): Observable<{
        mortgageChart: ChartOptions[];
    }> {
        return combineLatest([
            this.getAllMortgageChart(parameters),
        ]).pipe(
            map(([
                     mortgageChart,
                 ]) => ({
                mortgageChart: mortgageChart,
            }))
        );
    }


}

import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseNavigationModule } from 'fuseui';
import { FuseLoadingBarModule } from 'fuseui';
import { FuseFullscreenModule } from 'fuseui';
import { LanguagesModule } from '../../../common/languages/languages.module';
import { MessagesModule } from '../../../common/messages/messages.module';
import { NotificationsModule } from '../../../common/notifications/notifications.module';
import { QuickChatModule } from '../../../common/quick-chat/quick-chat.module';
import { SearchModule } from '../../../common/search/search.module';
import { ShortcutsModule } from '../../../common/shortcuts/shortcuts.module';
import { UserModule } from '../../../common/user/user.module';
import { ClassyLayoutComponent } from './classy.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'libs/kfp/src/lib/video-dialog';
import { VideoDialogModule } from '../../../../utils/video-dialog/video-dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import {AngularSvgIconModule} from 'angular-svg-icon';

@NgModule({
    declarations: [ClassyLayoutComponent],
    imports: [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseFullscreenModule,
        FuseLoadingBarModule,
        FuseNavigationModule,
        LanguagesModule,
        MessagesModule,
        NotificationsModule,
        QuickChatModule,
        SearchModule,
        ShortcutsModule,
        UserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DialogModule,
        VideoDialogModule,
        TranslateModule,
        AngularSvgIconModule,
    ],
    exports: [ClassyLayoutComponent],
})
export class ClassyLayoutModule {}

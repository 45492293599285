import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, EMPTY, filter, map, Observable, of, ReplaySubject, Subscription, switchMap, take, tap } from 'rxjs';
import { GetUsersFromCompany200ResponseInner, UserService as UserApiService } from '../api';
import {
    UserResource,
    AuthorizationService,
    RoleService,
    AuthorizationPasswordService,
    AssignRoleToUserRequest,
    ResetPasswordRequest,
    ResetUserPasswordRequest,
} from '../api';
 
@Injectable({
    providedIn: 'root',
})
export class UserService {
    private _userLogged = new BehaviorSubject<UserResource | null>(null);
    private _users: BehaviorSubject<GetUsersFromCompany200ResponseInner[] | null> = new BehaviorSubject<
        GetUsersFromCompany200ResponseInner[] | null
    >(null);
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private readonly _accessService: AuthorizationService,
        private readonly userApiService: UserApiService,
        private _roleService: RoleService,
        private _authPassService: AuthorizationPasswordService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: UserResource | null) {
        // Store the value
        this._userLogged.next(value);
    }

    get user$(): Observable<UserResource | null> {
        return this._userLogged.asObservable().pipe(
            switchMap((user: UserResource | null) => {
                if (!user) {
                    return this.get().pipe(tap((newUser: UserResource) => this._userLogged.next(newUser)));
                }
                return of(user);
            })
        );
    }

    get users$(): Observable<GetUsersFromCompany200ResponseInner[] | null> {
        return this._users.asObservable().pipe(
            switchMap((users: GetUsersFromCompany200ResponseInner[] | null) => {
                if (!users) {
                    return this.getUsers();
                }
                return of(users);
            })
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    public getUsers() {
        return this.userApiService.getUsers().pipe(
            tap((users) => {
                this._users.next(users);
            })
        );
    }

    /**
     * Get the current logged in user data
     */
    get(): Observable<any> {
        return this._accessService.loggedUser();
    }

    getUserDetail(id: number | string): Observable<UserResource | null> {
        return this.userApiService.getUserById(String(id)).pipe(
            catchError((error) => {
                console.error(error);
                return of(null);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    // update(user: UserResource): Observable<any> {
    //     return this._httpClient.patch<UserResource>('api/common/user', { user }).pipe(
    //         map((response) => {
    //             this._userLogged.next(response);
    //         })
    //     );
    // }

    // public createUser(user: CreateUserRequest): Observable<UserResource> {
    //     return this.userApiService.createNewUser(user)
    //     .pipe()
    //     .subscribe(
    //         (params) => {
    //             this.getUsers().subscribe();
    //             this.toastr.success('Uživatel vytvořen');
    //             this._router.navigate(['users/' + (params as unknown as UserResource).id]);
    //         },
    //         (error) => {
    //             this.toastr.error(error?.error?.error, 'Nastala chyba');
    //         }
    //     );
    // }

    public uploadAvatar(id: string, file: File): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.userApiService.uploadAvatarToUser(id, file);
    }

    public deleteAvatar(id: string): Observable<any> {
        return this.userApiService.deleteAvatarFromUser(id);
    }

    public assignRole(roleReq: AssignRoleToUserRequest): Observable<any> {
        return this._roleService.assignRoleToUser(roleReq);
    }

    public setNewPassword(req: ResetPasswordRequest): Observable<UserResource> {
        return this._authPassService.resetPassword(req);
    }

    public changeUserPassword(req: ResetUserPasswordRequest): Observable<UserResource> {
        return this._authPassService.resetUserPassword(req);
    }
}

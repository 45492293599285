import {formatNumber} from '@angular/common';
import {Injectable, Input} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {ChartOptions} from '../mortgage-chart.service';
import {AuthService} from '../../../../auth/auth.service';
import {SeriesLabel} from '../mortgage.service';
import {MortgageParametersService} from '../mortgage-parameters.service';
import {cloneDeep} from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {kfpColors} from '../../../../../../../../apps/fuse/src/assets/colors';

@Injectable({
    providedIn: 'root',
})
export class MortgageChart {
    public chart!: ChartOptions;
    logo?: string;
    @Input() height?: number;

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);

    data$ = this.dataChart.asObservable();
    serieLabels: any;

    constructor(private authService: AuthService, private _mortgageParamService: MortgageParametersService, private translateService: TranslateService) {

        this._mortgageParamService.selectedTab$.pipe().subscribe((tab) => {
            this.setActiveSeries([tab]);
        });

        this.height = window.innerHeight - 180;

        this.logo = this.authService.getLogoPath();

        this.dataChart.next({
            series: [],
            chart: {
                background:
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' + this.logo + ') no-repeat center; background-size: auto 30%;',
                type: 'line',
                height: this.height,
                width: '100%',
                toolbar: {
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true, // icon hidden, styles.scss
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: true,
                    },
                    autoSelected: 'zoom',
                },
                defaultLocale: 'cs',
                locales: [{
                    name: 'cs',
                    options: {
                        toolbar: {
                            reset: this.translateService.instant('apexchart.toolbar.reset'),
                            pan: this.translateService.instant('apexchart.toolbar.pan'),
                            zoomIn: this.translateService.instant('apexchart.toolbar.zoomIn'),
                            zoomOut: this.translateService.instant('apexchart.toolbar.zoomOut'),
                            selection: this.translateService.instant('apexchart.toolbar.selection'),
                            download: this.translateService.instant('apexchart.toolbar.download'),
                            exportToCSV: this.translateService.instant('apexchart.toolbar.exportToCSV')
                        }
                    }
                }],
            },
            stroke: {
                curve: 'smooth',
                width: 4,
            },
            colors: [kfpColors.kfpHypoDefault, kfpColors.kfpInvestDefault],
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.4,
                    opacityFrom: 0.7,
                    opacityTo: 0.1,
                    stops: [0, 100],
                },
            },
            xaxis: {
                type: 'numeric',
                tickPlacement: 'on',
                tickAmount: 10,
                tooltip: {
                    enabled: false,
                },
                labels: {
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    style: {
                        colors: '#95959a',
                    },
                    formatter: (value: any) => formatNumber(value, 'cs', '1.0-0'),
                },
            } as any,
            yaxis: {
                forceNiceScale: true,
                decimalsInFloat: 0,
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                    formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                },
            },
            dataLabels: {
                offsetY: -25,
                formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                style: {
                    fontSize: '12px',
                    colors: ['#002B4F'],
                },
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                position: 'bottom',
                horizontalAlign: 'left',
                itemMargin: {
                    horizontal: 25,
                    vertical: 25,
                },
                markers: {
                    radius: 5,
                    width: 15,
                    height: 8,
                    offsetY: -4,
                },
                labels: {
                    colors: '#95959a',
                },
            },
            annotations: {},
            tooltip: {
                enabled: true,

                custom: (opt: any) => {

                    const seriesMonth = this.dataChart.value?.series[opt.seriesIndex].data[opt.dataPointIndex][0];

                    const months = Math.round(seriesMonth);
                    const years = Math.floor(months / 12);
                    const remainingMonths = months % 12;

                    let formattedString = `${months}. ${this.translateService.instant('mortgage.calculation.basic.month')}`;

                    if (years > 0 || remainingMonths > 0) {
                        let yearsString = '';
                        if (years > 0) {
                            yearsString = years >= 5 ? `${years}` + this.translateService.instant('mortgage.calculation.basic.yearss') : `${years} ${years === 1 ? this.translateService.instant('mortgage.calculation.basic.year') : this.translateService.instant('mortgage.calculation.basic.years')}`;
                        }
                        let monthsString = '';
                        if (remainingMonths > 0) {
                            if (remainingMonths === 1) {
                                monthsString = this.translateService.instant('mortgage.calculation.basic.1month');
                            } else if (remainingMonths >= 2 && remainingMonths <= 4) {
                                monthsString = `${remainingMonths}` + this.translateService.instant('mortgage.calculation.basic.months');
                            } else {
                                monthsString = `${remainingMonths}` + this.translateService.instant('mortgage.calculation.basic.monthss');
                            }
                        }

                        const conjunction = yearsString && monthsString ? this.translateService.instant('mortgage.calculation.basic.and') : '';

                        formattedString += ` (${yearsString}${conjunction}${monthsString})`;
                    }


                    let tooltipHTML = '<div class="apexcharts-theme-light">';
                    tooltipHTML += '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">';
                    tooltipHTML += formattedString
                    tooltipHTML += '</div>';

                    this.dataChart.value?.series.forEach((serie: any, index: number) => {
                        const dynamicColor = opt?.w?.config?.colors[index];

                        if (serie !== null && serie !== undefined) {

                            tooltipHTML += '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">';
                            tooltipHTML += '<span class="apexcharts-tooltip-marker ml-4" style="background-color:' + dynamicColor + ';"></span>';
                            tooltipHTML += '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">';
                            tooltipHTML += '<div class="apexcharts-tooltip-y-group">';
                            tooltipHTML += '<span class="apexcharts-tooltip-text-label">' + serie.name + ': </span>';

                            // Apply formatNumber directly here
                            const formattedNumber = serie?.data?.find((innerArray: any[]) => innerArray[0] === seriesMonth)?.[1];
                            const formattedValue = formattedNumber !== null ? formatNumber(formattedNumber, 'cs', '1.0-0') : '---';
                            const displayCurrency = formattedNumber !== null ? this.authService.currency : '';

                            tooltipHTML += '<span class="apexcharts-tooltip-text-value font-semibold">' + formattedValue + '&nbsp;' + displayCurrency + '</span>';

                            tooltipHTML += '</div>';
                            tooltipHTML += '<div class="apexcharts-tooltip-z-group">';
                            tooltipHTML += '<span class="apexcharts-tooltip-text-z-label"></span>';
                            tooltipHTML += '<span class="apexcharts-tooltip-text-z-value"></span>';
                            tooltipHTML += '</div>';
                            tooltipHTML += '</div>';
                            tooltipHTML += '</div>';

                            tooltipHTML += '<div class="apexcharts-tooltip-series-group apexcharts-active -mt-4" style="order: 1; display: flex;">';
                            tooltipHTML += '<span class="apexcharts-tooltip-marker ml-4" style="background-color:' + dynamicColor + '; opacity: 0.5; scale: 0.5"></span>';
                            tooltipHTML += '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 10px;">';
                            tooltipHTML += '<div class="apexcharts-tooltip-y-group">';
                            tooltipHTML += '<span class="apexcharts-tooltip-text-label">' +

                                (serie?.seriesIndex ?
                                    this.translateService.instant('mortgage.calculation.investment.input.evaluation') :
                                    this.translateService.instant('mortgage.calculation.params.input.mortgage_rate'))
                                + ': </span>';

                            const formattedNumber2 = serie?.data?.find((innerArray: any[]) => innerArray[0] === seriesMonth)?.[3];
                            const formattedInterestRate = formattedNumber !== null ? formatNumber(formattedNumber2 * 100, 'cs', '1.0-2') + ' %' : '---';

                            tooltipHTML += '<span class="apexcharts-tooltip-text-value font-semibold">' + formattedInterestRate + '&nbsp;' + '</span>';

                            tooltipHTML += '</div>';
                            tooltipHTML += '<div class="apexcharts-tooltip-z-group">';
                            tooltipHTML += '<span class="apexcharts-tooltip-text-z-label"></span>';
                            tooltipHTML += '<span class="apexcharts-tooltip-text-z-value"></span>';
                            tooltipHTML += '</div>';
                            tooltipHTML += '</div>';
                            tooltipHTML += '</div>';


                            tooltipHTML += '<div class="apexcharts-tooltip-series-group apexcharts-active -mt-4" style="order: 1; display: flex;">';
                            tooltipHTML += '<span class="apexcharts-tooltip-marker ml-4" style="background-color:' + dynamicColor + '; opacity: 0.5; scale: 0.5"></span>';
                            tooltipHTML += '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 10px;">';
                            tooltipHTML += '<div class="apexcharts-tooltip-y-group">';
                            tooltipHTML += '<span class="apexcharts-tooltip-text-label">' +

                                (serie?.seriesIndex ?
                                    this.translateService.instant('fin_math.calculation.tab.parameters.investment_monthly_investment_short') :
                                    this.translateService.instant('fin_math.calculation.tab.parameters.monthly_payment'))
                                + ': </span>';

                            const formattedNumber3 = serie?.data?.find((innerArray: any[]) => innerArray[0] === seriesMonth)?.[5];
                            const formattedMonthlyPayment = formattedNumber3 !== null ? formatNumber(formattedNumber3, 'cs', '1.0-0') : '---';
                            const displayCurrency3 = formattedNumber !== null ? this.authService.currency : '';

                            tooltipHTML += '<span class="apexcharts-tooltip-text-value font-semibold">' + formattedMonthlyPayment + '&nbsp;' + displayCurrency3 + '</span>';

                            tooltipHTML += '</div>';
                            tooltipHTML += '<div class="apexcharts-tooltip-z-group">';
                            tooltipHTML += '<span class="apexcharts-tooltip-text-z-label"></span>';
                            tooltipHTML += '<span class="apexcharts-tooltip-text-z-value"></span>';
                            tooltipHTML += '</div>';
                            tooltipHTML += '</div>';
                            tooltipHTML += '</div>';


                        }

                        const events = serie?.data?.find((innerArray: any[]) => innerArray[0] === seriesMonth)?.[2];

                        if (events !== null && events?.length > 0) {
                            events.forEach((event: any) => {
                                tooltipHTML += '<div class="apexcharts-tooltip-series-group apexcharts-active -mt-2" style="order: 1; display: flex;">';
                                tooltipHTML += '<span class="apexcharts-tooltip-marker ml-4" style="background-color:' + dynamicColor + '; opacity: 0.8; scale: 0.5"></span>';
                                tooltipHTML += '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">';
                                tooltipHTML += '<div class="apexcharts-tooltip-y-group">';

                                const translatedLabel = this.translateService.instant('mortgage.calculation.event.' + event.type);
                                tooltipHTML += '<span class="apexcharts-tooltip-text-label">' + translatedLabel + ': </span>';

                                tooltipHTML += '<span class="apexcharts-tooltip-text-value font-semibold">';

                                if (['mortgage_pay', 'mortgage_payup', 'invest_pay', 'invest_payup', 'invest_new_pay'].includes(event.type)) {
                                    tooltipHTML += formatNumber(event.value, 'cs', '1.0-0') + '&nbsp;' + this.authService.currency;
                                } else {
                                    tooltipHTML += formatNumber(event.value, 'cs', '1.0-2') + '&nbsp;%';
                                }
                                tooltipHTML += '</span>';

                                tooltipHTML += '</div>';
                                tooltipHTML += '<div class="apexcharts-tooltip-z-group">';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-z-label"></span>';
                                tooltipHTML += '<span class="apexcharts-tooltip-text-z-value"></span>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '</div>';
                                tooltipHTML += '</div>';

                                // Comment for event
                                if (event?.comment?.length > 0) {
                                    tooltipHTML += '<div class="apexcharts-tooltip-series-group apexcharts-active -mt-4 ml-9" style="order: 1; display: flex;">';
                                    tooltipHTML += '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 11px;">';
                                    tooltipHTML += '<div class="apexcharts-tooltip-y-group">';

                                    tooltipHTML += '<span class="apexcharts-tooltip-text-label">' + '(' + event.comment + ')' + ' </span>';

                                    tooltipHTML += '</div>';
                                    tooltipHTML += '<div class="apexcharts-tooltip-z-group">';
                                    tooltipHTML += '<span class="apexcharts-tooltip-text-z-label"></span>';
                                    tooltipHTML += '<span class="apexcharts-tooltip-text-z-value"></span>';
                                    tooltipHTML += '</div>';
                                    tooltipHTML += '</div>';
                                    tooltipHTML += '</div>';
                                }


                            });
                        }

                    });

                    tooltipHTML += '</div>';
                    return tooltipHTML;
                },
            },
            responsive: {
                breakpoint: 1000,
                options: {
                    plotOptions: {
                        bar: {
                            horizontal: false,
                        },
                        scales: {
                            xAxes: [{
                                type: 'linear'
                            }]
                        } as any
                    },
                },
            },
        });
    }

    updateSeries(newValue?: SeriesLabel[]): void {
        this.logo = this.authService.getLogoPath();
        if (newValue) {
            this.serieLabels = newValue;
            this._mortgageParamService.setChartData(newValue);
        }
        this.setActiveSeries([this._mortgageParamService.getSelectedTab()]);

    }

    setActiveSeries(activeCalcs: number[]) {    // Its ready to be multiple active cals at once. (array)

        // Initialize an empty object to store updatedObject
        let updatedObject: any = {};

        // Check if there is a current object in dataChart
        const currentObject = this.dataChart.value;

        if (currentObject === null || currentObject === undefined ||
            activeCalcs === null || activeCalcs === undefined ||
            this.serieLabels === null || this.serieLabels === undefined ||
            activeCalcs?.length > this.serieLabels?.length) {
            return;
        }

        if (currentObject) {
            // Clone the currentObject
            updatedObject = {...currentObject};

            // Update series and labels based on activeCalcs
            const updatedSeries = activeCalcs.map(index => cloneDeep(this.serieLabels[index]));

            if (updatedSeries === null || updatedSeries === undefined) {
                return;
            }

            // Find the series with the longest labels array
            const longestSeries = updatedSeries.reduce((longest, series) =>
                    series?.labels.length > longest?.labels.length ? series : longest,
                updatedSeries[0]
            );

            if (longestSeries === null || longestSeries === undefined) {
                return;
            }
            const longestSeriesLength = longestSeries.labels.length
            // Retrieve the labels array of the longest series
            const longestLabels = longestSeries.labels;


            // Iterate over each series and pad them with null values if necessary
            updatedSeries.forEach((series) => {
                const currentSeriesLength = series.labels.length;
                if (currentSeriesLength < longestSeriesLength) {
                    series.labels = longestLabels;

                    series.series.forEach((s: any) => {

                        s.data = this.generatePaddedData(s.data, longestSeriesLength);


                    });
                }
            });

            // Flatten the series arrays
            updatedObject.series = updatedSeries.flatMap(series => series.series);
            updatedObject.labels = updatedSeries.flatMap(series => series.labels);

            // Update yaxis min and max if they exist based on all selected series in activeCalcs
            if (updatedObject.yaxis && activeCalcs.length > 0) {
                let minValues = this.serieLabels[activeCalcs[0]].min;
                let maxValues = this.serieLabels[activeCalcs[0]].max;

                // Iterate over all selected series and update minValues and maxValues
                for (let i = 1; i < activeCalcs.length; i++) {
                    const seriesIndex = activeCalcs[i];
                    const series = this.serieLabels[seriesIndex];
                    minValues = Math.min(minValues, series.min);
                    maxValues = Math.max(maxValues, series.max);
                }

                // Set updated min and max values to yaxis
                updatedObject.yaxis.min = minValues;
                updatedObject.yaxis.max = maxValues;
            }
        }
        this.logo = this.authService.getLogoPath();
        if (this.logo) {
            updatedObject.chart.background =
                'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' +
                this.logo +
                ') no-repeat center; background-size: auto 30%;';
        }

        this.pointHandler(updatedObject, activeCalcs);

        // Emit updatedObject to dataChart
        this.dataChart.next(updatedObject);
    }

    pointHandler(updatedObject: any, activeCalcs: any) {

        if (this.serieLabels[activeCalcs]?.allEvents) {
            const points = this.serieLabels[activeCalcs].allEvents.map((event: any) => ({
                x: event.x,
                y: event.y,
                marker: {
                    size: 6,
                    strokeWidth: 2,
                    fillColor: event?.seriesIndex ? kfpColors.kfpInvestDefault : kfpColors.kfpHypoDefault,
                    strokeColor: '#ffffff'
                }
            }));

            updatedObject.annotations = {
                points: points
            };
        }
    }

    generatePaddedData(records: any, maxSeriesLength: number) {

        const lastValidEntry = records[records.length - 1]; // Get the last valid entry
        const paddedData = [...records]; // Create a copy of original data

        // Generate the padded data dynamically
        for (let i = records.length; i < maxSeriesLength; i++) {
            const nextYearIndex = lastValidEntry[0] + (i - records.length + 1);
            paddedData.push([nextYearIndex, null]);
        }

        return paddedData;
    }

    getChartData() {
        return this.dataChart;
    }

    getAllChartsData(res: SeriesLabel[]): Observable<ChartOptions[]> {
        const newDataCharts: any[] = Array.from({ length: res.length }, () => cloneDeep(this.dataChart.value));

        newDataCharts.forEach((chartOptions: any, index) => {

            const activeCalcs: any = [index];
            const serieLabels: any = res;

            let updatedObject: any = {};

            const currentObject = newDataCharts[index];

            if (currentObject === null || currentObject === undefined || serieLabels === null || serieLabels === undefined ||
                activeCalcs?.length > serieLabels?.length) {
                return;
            }

            if (currentObject) {
                updatedObject = {...currentObject};

                const updatedSeries = activeCalcs.map(index => cloneDeep(serieLabels[index]));

                if (updatedSeries === null || updatedSeries === undefined) {
                    return;
                }


                // Find the series with the longest labels array
                const longestSeries = updatedSeries.reduce((longest, series) =>
                        series?.labels.length > longest?.labels.length ? series : longest,
                    updatedSeries[0]
                );

                if (longestSeries === null || longestSeries === undefined) {
                    return;
                }
                const longestSeriesLength = longestSeries.labels.length
                // Retrieve the labels array of the longest series
                const longestLabels = longestSeries.labels;


                // Iterate over each series and pad them with null values if necessary
                updatedSeries.forEach((series) => {
                    const currentSeriesLength = series.labels.length;
                    if (currentSeriesLength < longestSeriesLength) {
                        series.labels = longestLabels;

                        series.series.forEach((s: any) => {

                            s.data = this.generatePaddedData(s.data, longestSeriesLength);


                        });
                    }
                });

                // Flatten the series arrays
                updatedObject.series = updatedSeries.flatMap(series => series.series);
                updatedObject.labels = updatedSeries.flatMap(series => series.labels);

                // Update yaxis min and max if they exist based on all selected series in activeCalcs
                if (updatedObject.yaxis && activeCalcs.length > 0) {
                    let minValues = serieLabels[activeCalcs[0]].min;
                    let maxValues = serieLabels[activeCalcs[0]].max;

                    // Iterate over all selected series and update minValues and maxValues
                    for (let i = 1; i < activeCalcs.length; i++) {
                        const seriesIndex = activeCalcs[i];
                        const series = serieLabels[seriesIndex];
                        minValues = Math.min(minValues, series.min);
                        maxValues = Math.max(maxValues, series.max);
                    }

                    // Set updated min and max values to yaxis
                    updatedObject.yaxis.min = minValues;
                    updatedObject.yaxis.max = maxValues;
                }
            }
            this.logo = this.authService.getLogoPath();
            if (this.logo) {
                updatedObject.chart.background =
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' +
                    this.logo +
                    ') no-repeat center; background-size: auto 30%;';
            }

            if (serieLabels[activeCalcs]?.allEvents) {
                const points = serieLabels[activeCalcs].allEvents.map((event: any) => ({
                    x: event.x,
                    y: event.y,
                    marker: {
                        size: 6,
                        strokeWidth: 2,
                        fillColor: event?.seriesIndex ? kfpColors.kfpInvestDefault : kfpColors.kfpHypoDefault,
                        strokeColor: '#ffffff'
                    }
                }));

                updatedObject.annotations = {
                    points: points
                };
            }

            newDataCharts[index] = updatedObject;

        });
        return of(newDataCharts); // Wrap the array in an observable
    }


}

/* tslint:disable:max-line-length */
import { FuseNavigationItem } from 'fuseui';

export let defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Přehled',
        type: 'basic',
        icon: 'heroicons_outline:template',
        link: '/dashboard',
        role: 'other',
    },
    {
        id: 'calculations',
        title: 'Kalkulace',
        type: 'basic',
        icon: 'heroicons_outline:chart-square-bar',
        link: '/calculations',
        role: 'other',
    },
    {
        id: 'profile',
        title: 'Můj účet',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/profile',
        role: 'other',
        exactMatch: true,
    },
    {
        id: 'branding',
        title: 'Branding',
        type: 'basic',
        icon: 'heroicons_outline:color-swatch',
        link: '/profile/branding',
        role: 'other',
        exactMatch: true,
    },
    {
        id: 'subscription',
        title: 'Předplatné',
        type: 'basic',
        icon: 'heroicons_outline:star',
        link: '/profile/subscription',
        role: 'other',
        exactMatch: true,
    },
];

export let navigationEn: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Overview',
        type: 'basic',
        icon: 'heroicons_outline:template',
        link: '/dashboard',
        role: 'other',
    },

    {
        id: 'calculations',
        title: 'Calculations',
        type: 'basic',
        icon: 'heroicons_outline:chart-square-bar',
        link: '/calculations',
        role: 'other',
    },
    {
        id: 'profile',
        title: 'My account',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/profile',
        role: 'other',
        exactMatch: true,
    },
    {
        id: 'branding',
        title: 'Branding',
        type: 'basic',
        icon: 'heroicons_outline:color-swatch',
        link: '/profile/branding',
        role: 'other',
        exactMatch: true,
    },
    {
        id: 'subscription',
        title: 'Subscription',
        type: 'basic',
        icon: 'heroicons_outline:star',
        link: '/profile/subscription',
        role: 'other',
        exactMatch: true,
    },
];

export let navigationSk: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Prehľad',
        type: 'basic',
        icon: 'heroicons_outline:template',
        link: '/dashboard',
        role: 'other',
    },

    {
        id: 'calculations',
        title: 'Kalkulácia',
        type: 'basic',
        icon: 'heroicons_outline:chart-square-bar',
        link: '/calculations',
        role: 'other',
    },
    {
        id: 'profile',
        title: 'Môj účet',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/profile',
        role: 'other',
        exactMatch: true,
    },
    {
        id: 'branding',
        title: 'Branding',
        type: 'basic',
        icon: 'heroicons_outline:color-swatch',
        link: '/profile/branding',
        role: 'other',
        exactMatch: true,
    },
    {
        id: 'subscription',
        title: 'Predplatné',
        type: 'basic',
        icon: 'heroicons_outline:star',
        link: '/profile/subscription',
        role: 'other',
        exactMatch: true,
    },
];

export const defaultNavigationNotAdmin: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Přehled',
        type: 'basic',
        icon: 'heroicons_outline:template',
        link: '/dashboard',
        role: 'other',
    },
    {
        id: 'calculations',
        title: 'Kalkulace',
        type: 'basic',
        icon: 'heroicons_outline:chart-square-bar',
        link: '/calculations',
        role: 'other',
    },
    {
        id: 'profile',
        title: 'Můj účet',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/profile',
        role: 'other',
    },
];

export const compactNavigation: FuseNavigationItem[] = [];
export const futuristicNavigation: FuseNavigationItem[] = [];
export const horizontalNavigation: FuseNavigationItem[] = [];
export const horizontalNavigationNotAdmin: FuseNavigationItem[] = [];

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { CreateHistoryDataRequest } from 'kfp';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexLegend,
    ApexMarkers,
    ApexPlotOptions,
    ApexResponsive,
    ApexStroke,
    ApexTitleSubtitle,
    ApexTooltip,
    ApexXAxis,
    ApexYAxis,
} from 'ng-apexcharts';
import { BehaviorSubject, combineLatest, map, Observable, switchMap } from 'rxjs';
import { EvaluationChart } from '../charts/evaluation-chart';
import { EvolutionChart } from '../charts/evolution-chart';
import { MarketChart } from '../charts/market-chart';
import { ProfitChart } from '../charts/profit-chart';
import { ProfitChartPercent } from '../charts/profit-chart-percent';
import { HistoricalParametersService } from './historical-parameters.service';
import { HistoricalService } from './historical.service';
import {kfpColors} from '../../../../../../../../apps/fuse/src/assets/colors';

export type HistoricalChartType = 'EVOLUTION' | 'EVALUATION' | 'MARKET' | 'PROFIT' | 'PERCENT';
export type HistoricalChartOptions = {
    params?: any;
};

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis?: ApexXAxis;
    stroke?: ApexStroke;
    dataLabels?: ApexDataLabels;
    yaxis?: ApexYAxis;
    title?: ApexTitleSubtitle;
    labels?: string[];
    legend?: ApexLegend;
    subtitle?: ApexTitleSubtitle;
    plotOptions?: ApexPlotOptions;
    colors: string[];
    tooltip: ApexTooltip;
    fill?: ApexFill;
    markers?: ApexMarkers;
    responsive?: ApexResponsive;
};

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class HistoricalChartService {
    public locale = 'cs';

    constructor(
        public translateService: TranslateService,
        private _httpClient: HttpClient,
        private _historicalService: HistoricalService,
        private readonly _evaluationChart: EvaluationChart,
        private readonly _evolutionChart: EvolutionChart,
        private readonly _marketChart: MarketChart,
        private readonly _profitChart: ProfitChart,
        private readonly _profitChartPercent: ProfitChartPercent,
        public parametersService: HistoricalParametersService
    ) {}

    public evaluationChart(parameters: CreateHistoryDataRequest): Observable<ChartOptions> {
        return this._historicalService.dataEvaluation(parameters).pipe(
            switchMap((res) => {
                this._evaluationChart.updateSeries(res);
                return this._evaluationChart.getChartData();
            })
        );
    }

    public evolutionChart(parameters: CreateHistoryDataRequest): Observable<ChartOptions> {
        return this._historicalService.dataEvolution(parameters).pipe(
            untilDestroyed(this),
            switchMap((res) => {
                this._evolutionChart.updateSeries(res);
                return this._evolutionChart.getChartData();
            })
        );
    }

    public marketChart(parameters: CreateHistoryDataRequest): Observable<ChartOptions> {
        return this._historicalService.dataMarket(parameters).pipe(
            untilDestroyed(this),
            switchMap((res) => {
                this._marketChart.updateSeries(res);
                return this._marketChart.getChartData();
            })
        );
    }

    public profitChart(parameters: CreateHistoryDataRequest): Observable<ChartOptions> {
        return this._historicalService.dataProfit(parameters, 'VALUE').pipe(
            untilDestroyed(this),
            switchMap((res) => {
                this._profitChart.updateSeries(res);
                return this._profitChart.getChartData();
            })
        );
    }

    public profitChartPercent(parameters: CreateHistoryDataRequest): Observable<ChartOptions> {
        return this._historicalService.dataProfit(parameters, 'PERCENT').pipe(
            untilDestroyed(this),
            switchMap((res) => {
                this._profitChartPercent.updateSeries(res);
                return this._profitChartPercent.getChartData();
            })
        );
    }

    public chartByType(type: HistoricalChartType, parameters: CreateHistoryDataRequest, options?: HistoricalChartOptions) {
        switch (type) {
            case 'EVALUATION':
                return this.evaluationChart(parameters);
            case 'EVOLUTION':
                return this.evolutionChart(parameters);
            case 'MARKET':
                return this.marketChart(parameters);
            case 'PROFIT':
                return this.profitChart(parameters);
            case 'PERCENT':
                return this.profitChartPercent(parameters);
        }
    }

    public allocationChart(parameters: CreateHistoryDataRequest, mode: string = 'dynamic'): Observable<ChartOptions> {
        const data: number[] = [
            parameters.shares ? parameters.shares : 0,
            parameters.bonds ? parameters.bonds : 0,
            parameters.finMarket ? parameters.finMarket : 0,
        ];

        // @ts-ignore
        return this.translateService
            .stream([
                'historical.calculation.chart.stock',
                'historical.calculation.chart.bonds',
                'historical.calculation.chart.money_market',
                'historical.calculation.chart.dynamic',
                'historical.calculation.chart.balanced',
                'historical.calculation.chart.conservative',
                'historical.calculation.chart.custom',
            ])
            .pipe(
                switchMap((keys) => {
                    let modeTitle = '';
                    switch (mode) {
                        case 'dynamic':
                            modeTitle = keys['historical.calculation.chart.dynamic'];
                            break;
                        case 'balanced':
                            modeTitle = keys['historical.calculation.chart.balanced'];
                            break;
                        case 'conservative':
                            modeTitle = keys['historical.calculation.chart.conservative'];
                            break;
                        case 'custom':
                            modeTitle = keys['historical.calculation.chart.custom'];
                            break;
                    }
                    const chartOptions = {
                        series: data,
                        labels: [
                            keys['historical.calculation.chart.stock'],
                            keys['historical.calculation.chart.bonds'],
                            keys['historical.calculation.chart.money_market'],
                        ],
                        chart: {
                            type: 'donut',
                            offsetY: 20,
                        },
                        colors: [kfpColors.kfpStocks, kfpColors.kfpBonds, kfpColors.kfpMarket],
                        plotOptions: {
                            pie: {
                                expandOnClick: false,
                                donut: {
                                    size: '85%',
                                    labels: {
                                        show: true,
                                        name: {
                                            show: true,
                                            fontSize: '20px',
                                            fontWeight: 800,
                                            color: '#002B4F',
                                            offsetY: 0,
                                            formatter: () => modeTitle,
                                        },
                                        value: {
                                            show: true,
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            color: '#8E8E93',
                                            offsetY: 5,
                                            formatter: () => this.translateService.instant('historical.calculation.chart.without_break'),
                                        },
                                        total: {
                                            show: true,
                                            showAlways: true,
                                            fontSize: '20px',
                                            fontWeight: 800,
                                            color: '#002B4F',
                                            offsetY: 0,
                                            formatter: () => this.translateService.instant('historical.calculation.chart.without_break'),
                                        },
                                    },
                                },
                            },
                        },
                        legend: {
                            show: false,
                        },
                        tooltip: {
                            enabled: false,
                        },
                        dataLabels: {
                            enabled: false,
                        },
                    } as unknown as ChartOptions;

                    return new BehaviorSubject<ChartOptions>(chartOptions).asObservable();
                })
            );
    }

    prepareDataForPdfExport(parameters: CreateHistoryDataRequest): Observable<{
        evolution: ChartOptions;
        evaluation: ChartOptions;
        profitValue: ChartOptions;
        profitPercent: ChartOptions;
        market: ChartOptions;
        allocation: ChartOptions;
        breakChart: ChartOptions;
    }> {
        return combineLatest([
            this.evolutionChart(parameters),
            this.evaluationChart(parameters),
            this.profitChart(parameters),
            this.profitChartPercent(parameters),
            this.marketChart(parameters),
            this.allocationChart(parameters),
        ]).pipe(
            map(([evolution, evaluation, profitValue, profitPercent, market, allocation]) => ({
                evolution: evolution,
                evaluation: evaluation,
                profitValue: profitValue,
                profitPercent: profitPercent,
                market: market,
                allocation: allocation,
                breakChart: this.parametersService.breakChart,
            }))
        );
    }
}

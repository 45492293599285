import { formatNumber } from '@angular/common';
import { Injectable, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, of } from 'rxjs';
import { ChartOptions } from '../services/probability-chart.service';
import { AuthService } from '../../../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import {kfpColors} from '../../../../../../../../apps/fuse/src/assets/colors';

@Injectable({
    providedIn: 'root',
})
export class ProbabilityHistoryChart {
    public chart!: ChartOptions;
    data: any;
    logo?: string;
    @Input() height?: number;

    public horizons = ['horizonRecords1', 'horizonRecords3', 'horizonRecords5', 'horizonRecords10', 'horizonRecords20', 'horizonRecords30'];

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);
    private dataCharts: BehaviorSubject<ChartOptions>[] = [];

    data$ = this.dataChart.asObservable();
    constructor(private authService: AuthService, private translateService: TranslateService) {
        if (window.innerWidth > 1425) {
            this.height = (window.innerHeight * 70) / 100;
        } else {
            this.height = (window.innerHeight * 65) / 100 - 75;
        }
        this.logo = this.authService.getLogoPath();
        this.dataChart.next({
            series: [
                {
                    name: 'Průměrný výnos (p.a.)',
                    data: [],
                },
            ],
            chart: {
                background:
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' + this.logo + ') no-repeat center; background-size: auto 30%;',
                type: 'bar',
                height: this.height,
                width: '100%',
                toolbar: {
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true, // icon hidden, styles.scss
                        zoomin: false,
                        zoomout: false,
                        pan: true,
                        reset: true,
                    },
                    autoSelected: 'zoom',
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    barHeight: '40%',
                    horizontal: false,
                    distributed: true,
                    rangeBarOverlap: true,
                    borderRadiusApplication: 'end',
                    dataLabels: {
                        position: 'top',
                        hideOverflowingLabels: false,
                    },
                },
            },
            colors: [kfpColors.kfpProbProfit],
            xaxis: {
                type: 'category',
                tooltip: {
                    enabled: false,
                },
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                },
            },
            yaxis: {
                forceNiceScale: true,
                decimalsInFloat: 0,
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                    formatter: (value: any) => formatNumber(value, 'cs', '1.0-1') + '%',
                },
            },
            dataLabels: {
                offsetY: -25,
                formatter: (_val: number, opt: any) =>
                    _val < 1
                        ? (formatNumber(_val, 'cs', '1.0-2') + ' %').toString()
                        : (formatNumber(_val, 'cs', '1.2-2') + ' %').toString(),
                style: {
                    fontSize: '12px',
                    colors: ['#002B4F'],
                },
            },
            legend: {
                show: false,
                showForSingleSeries: true,
                position: 'bottom',
                horizontalAlign: 'left',
                itemMargin: {
                    horizontal: 25,
                    vertical: 25,
                },
                markers: {
                    radius: 5,
                    width: 15,
                    height: 8,
                    offsetY: -4,
                },
                labels: {
                    colors: '#95959a',
                },
            },
            tooltip: {
                enabled: false,
            },
            responsive: {
                breakpoint: 1000,
                options: {
                    plotOptions: {
                        bar: {
                            horizontal: false,
                        },
                    },
                },
            },
        });
    }

    updateSeries(data: any): void {
        this.data = data;
        this.processDataCharts();
    }

    public getChartsData(): Observable<ChartOptions[]> {
        return [this.dataCharts] as any;
    }

    public processDataCharts(): any[] | null {
        if (this.data === undefined) {
            return null;
        }
        this.logo = this.authService.getLogoPath();

        const chartOptionsArray: any[] = [];

        this.horizons.forEach((selectedHorizon: string) => {
            const chartOptions = { ...this.dataChart.getValue() }; // Create a new object

            const series: { x: string; y: number }[] = [];
            const colors: any[] = [];

            this.data[selectedHorizon].forEach((value: any) => {
                const xValueMin = value.min ? formatNumber(value.min * 100, 'cs', '1.0-0') + ' %' : null;
                const xValueMax = value.max ? formatNumber(value.max * 100, 'cs', '1.0-0') + ' %' : null;
                const color = value.max * 100 > 0 || value.max == null ? kfpColors.kfpProbProfit : kfpColors.kfpPesimistic;
                colors.push(color);

                if (!xValueMax) {
                    series.push({
                        x: xValueMin + ' ' + this.translateService.instant('probability.calculation.chart.and_more'),
                        y: value.percentage * 100,
                    });
                }

                if (!xValueMin) {
                    series.push({
                        x: xValueMax + ' ' + this.translateService.instant('probability.calculation.chart.and_less'),
                        y: value.percentage * 100,
                    });
                }

                if (xValueMax && xValueMin) {
                    series.push({
                        x: xValueMin + ' ' + this.translateService.instant('probability.calculation.chart.until') + ' ' + xValueMax,
                        y: value.percentage * 100,
                    });
                }
            });


            if (this.logo && chartOptions?.chart) {
                chartOptions.chart.background =
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' +
                    this.logo +
                    ') no-repeat center; background-size: auto 30%;';
            }


            chartOptions.colors = colors;
            chartOptions.series = [{ data: [...series] }];
            chartOptionsArray.push({ ...chartOptions });
        });

        this.dataCharts = chartOptionsArray;
        return chartOptionsArray;
    }
}

import { formatNumber } from '@angular/common';
import { Injectable, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment';
import { ChartOptions } from '../services/historical-chart.service';
import { SeriesLabel } from '../services/historical.service';
import { AuthService } from '../../../../auth/auth.service';
import {kfpColors} from '../../../../../../../../apps/fuse/src/assets/colors';

@Injectable({
    providedIn: 'root',
})
export class EvolutionChart {
    public chart!: ChartOptions;
    logo?: string;
    @Input() height?: number;

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);

    data$ = this.dataChart.asObservable();
    constructor(private authService: AuthService) {
        if (window.innerWidth > 1425) {
            this.height = (window.innerHeight * 64) / 100;
        } else {
            this.height = (window.innerHeight * 65) / 100 - 75;
        }
        this.logo = this.authService.getLogoPath();
        this.dataChart.next({
            series: [],
            chart: {
                background:
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' + this.logo + ') no-repeat center; background-size: auto 30%;',
                width: '100%',
                stacked: false,
                type: 'line',
                height: this.height,
                zoom: {
                    type: 'xy',
                    enabled: true,
                    autoScaleYaxis: true,
                },
                toolbar: {
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true, // icon hidden, styles.scss
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: true,
                    },
                    autoSelected: 'zoom',
                },
            },
            colors: [kfpColors.kfpDepositAll, kfpColors.kfpProfit, kfpColors.kfpInvestProfit],
            stroke: {
                curve: ['straight', 'smooth', 'smooth'],
                width: [0, 4, 4],
            },
            fill: {
                type: ['solid', '', 'gradient'],
                opacity: [0.5, 1, 1],
                colors: [kfpColors.kfpDepositAll, kfpColors.kfpProfit, kfpColors.kfpInvestProfit],
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.2,
                    stops: [0, 50, 100],
                    type: 'vertical',
                    shade: 'light',
                },
            },
            markers: {
                colors: [kfpColors.kfpDepositAll, kfpColors.kfpProfit, kfpColors.kfpInvestProfit],
            },
            xaxis: {
                categories: [],
                type: 'datetime',
                tickAmount: 4,
                tooltip: {
                    enabled: false,
                },
                labels: {
                    datetimeFormatter: {
                        year: 'yyyy',
                        month: '',
                        day: '',
                    },
                    style: {
                        colors: '#95959a',
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                    formatter: (value: any) => formatNumber(value, 'cs', '1.0-0'),
                },
            },
            tooltip: {
                shared: true,
                intersect: false,
                x: {
                    show: true,
                    formatter: (value: any) => moment(value).format('MMMM  Y'),
                },
                y: {
                    formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                },
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'left',
                itemMargin: {
                    horizontal: 25,
                    vertical: 25,
                },
                markers: {
                    radius: 5,
                    width: 15,
                    height: 8,
                    offsetY: -4,
                },
                labels: {
                    colors: '#95959a',
                },
            },
        });
    }

    updateSeries(newValue: SeriesLabel): void {
        const currentObject = this.dataChart.value;
        if (currentObject) {
            let updatedObject = { ...currentObject };
            updatedObject.series = newValue.series;
            if (updatedObject.xaxis) {
                updatedObject.xaxis.categories = newValue.labels;
            }
            const yaxis = Object.assign({}, updatedObject.yaxis);
            yaxis.min = newValue.min;
            yaxis.max = newValue.max;
            updatedObject = { ...updatedObject, yaxis: yaxis };
            this.logo = this.authService.getLogoPath();
            this.dataChart.next(updatedObject);
        }
    }

    getChartData() {
        return this.dataChart;
    }
}

<div class="relative flex flex-col items-center flex-auto min-w-0 app-bg overflow-hidden">
    <div class="w-full align-center h-10 bg-white flex justify-center align-middle">
        <span class="font-normal text-center mt-2 align-middle">
            {{ 'section.auth.sign-in.info_1' | translate }}
            <a class="text-blue-900 underline font-semibold" href="/forgot-password">{{ 'section.auth.sign-in.info_2' | translate }}</a>
            {{ 'section.auth.sign-in.info_3' | translate }}
        </span>
    </div>

    <div class="absolute flex bottom-10 z-0 ">
        <svg-icon src="assets/icons/kfpicons/lines_login.svg"
                  class="justify-center opacity-5 text-primary2 border-3"></svg-icon>
    </div>

    <div class="md:flex md:items-center md:justify-end w-auto py-4 px-6 rounded-xl shadow-lg mt-[105px] sm:bg-card z-20">
        <div class="w-[350px] mx-0">
            <!-- Logo -->
            <div class="w-full flex justify-center mt-6">
                <div class="h-22 w-22 rounded-full shadow-lg bg-white overflow-hidden flex items-center justify-center p-3">
                    <img class="w-full h-auto" src="assets/images/cropped-KFP_logo_online_300px.svg" />
                </div>
            </div>
            <!-- Title -->
            <div class="w-full flex flex-col items-center">
                <div class="mt-8 text-[42px] font-bold tracking-tight leading-8 text-secondary">
                    {{ 'section.auth.sign-in.login' | translate }}
                </div>
                <div>
                    <hr class="h-1 w-20 mx-2 my-2 border-y-0 bg-primary"/>
                </div>
                <div class="text-lg font-normal tracking-tight leading-6 text-secondary ">KFP Online</div>
            </div>

            <!-- Alert -->
            <fuse-alert
                class="mt-8 -mb-4"
                *ngIf="showAlert"
                [appearance]="'outline'"
                [showIcon]="false"
                [type]="alert.type"
                [@shake]="alert.type === 'error'"
            >
                {{ alert.message }}
            </fuse-alert>

            <!-- Sign in form -->
            <form class="mt-4" [formGroup]="signInForm" #signInNgForm="ngForm">
                <!-- Email field -->
                <mat-form-field class="w-full">
                    <mat-label class="text-slate-500 font-light mb-2">{{ 'section.auth.sign-in.login-mail' | translate }}</mat-label>
                    <input id="email" matInput [formControlName]="'email'" />
                    <mat-error *ngIf="signInForm.get('email').hasError('required')">
                        {{ 'section.auth.sign-in.fill-mail' | translate }}
                    </mat-error>
                    <mat-error *ngIf="signInForm.get('email').hasError('email')">
                        {{ 'section.auth.sign-in.fill-valid-mail' | translate }}
                    </mat-error>
                </mat-form-field>

                <!-- Password field -->
                <mat-form-field class="w-full">
                    <mat-label class="text-slate-500 font-light mb-2 flex justify-between">
                        <span>{{ 'section.auth.sign-in.password' | translate }}</span>
                        <span
                            ><a class="underline" href="/forgot-password">{{ 'auth.forgot_pass' | translate }}</a></span
                        >
                    </mat-label>
                    <input id="password" matInput type="password" [formControlName]="'password'" #passwordField />
                    <button
                        mat-icon-button
                        type="button"
                        (click)="passwordField.type === 'password' ? (passwordField.type = 'text') : (passwordField.type = 'password')"
                        matSuffix
                    >
                        <i *ngIf="passwordField.type === 'password'" class="far fa-xs fa-eye"></i>
                        <i *ngIf="passwordField.type === 'text'" class="far fa-xs fa-eye-slash"></i>
                    </button>
                    <mat-error>
                        {{ 'section.auth.sign-in.fill-password' | translate }}
                    </mat-error>
                </mat-form-field>

                <!-- Submit button -->
                <button
                    class="fuse-mat-button-large w-full mt-2 bg-secondary text-white rounded-2xl text-lg py-7"
                    mat-flat-button
                    [color]="'primary'"
                    [disabled]="signInForm.disabled"
                    (click)="signIn()"
                >
                    <span *ngIf="!signInForm.disabled">
                        {{ 'section.auth.sign-in.login.button' | translate }}
                    </span>
                    <mat-progress-spinner *ngIf="signInForm.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
                </button>
            </form>

            <div class="flex justify-center items-center text-center mt-10 px-8 mb-4">
                <div class="text font-normal leading-4">
                    <span class="mr-3">{{ 'section.auth.sign-in.first_time.message' | translate }}</span>
                    <span class="cursor-pointer underline font-semibold" routerLinkActive="router-link-active" [routerLink]="['/register']">{{'section.auth.sign-in.create_account.button' | translate }}
                    </span>
                </div>

            </div>
        </div>
    </div>
</div>

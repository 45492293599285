/* eslint-disable */
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
    AuthorizationPasswordService,
    AuthorizationService,
    CalculationService,
    FlagService,
    LoginRequest,
    PasswordResetRequest,
    SendResetLinkRequest,
    UserResource,
    VideoService,
} from 'kfp';
import moment from 'moment/moment';
import { catchError, combineLatestWith, map, Observable, of, ReplaySubject, shareReplay, switchMap, tap, throwError } from 'rxjs';
import { AppService } from '../app-service/kfp.service';
import { HistoricalParametersService } from '../calculations/historical/domain/services/historical-parameters.service';
import { ProbabilityParametersService } from '../calculations/probability/domain/services/probability-parameters.service';
import { UserService } from '../users/users.service';
import { KfpStripeService } from '../utils/stripe/kfpstripe.service';
import { AuthUtils } from './auth.utils';

@Injectable()
export class AuthService {
    private _authenticated = false;

    private _user: ReplaySubject<UserResource> = new ReplaySubject<UserResource>(1);
    private observableUser: Observable<any> | null | undefined;
    private userSaved: any = null;

    constructor(
        public translateService: TranslateService,
        private _accessService: AuthorizationService,
        private _appService: AppService,
        private _authPassService: AuthorizationPasswordService,
        private _userService: UserService,
        private calcService: CalculationService,
        private _historicalParametersService: HistoricalParametersService,
        private _probabilityParametersService: ProbabilityParametersService,
        private kfpStripeService: KfpStripeService,
        private readonly flagService: FlagService,
        private readonly videoService: VideoService,
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        sessionStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        // @ts-ignore
        return sessionStorage.getItem('accessToken') && sessionStorage.getItem('accessToken') !== 'undefined'
            ? sessionStorage.getItem('accessToken')
            : '';
    }

    /**
     * Setter & getter for access token
     */
    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        const resetRequest: SendResetLinkRequest = {
            email: email,
        };
        // @ts-ignore
        return this._authPassService.sendResetLink(resetRequest).pipe(
            // @ts-ignore
            switchMap(response =>
                // Return a new observable with the response
                of(response),
            ),
        );
    }

    public changePassword(data: { email: any; token: any; password: any; passwordConfirmation: any }): Observable<any> {
        const resetRequest: PasswordResetRequest = {
            email: data.email,
            token: data.token,
            password: data.password,
            passwordConfirmation: data.passwordConfirmation,
        };
        // @ts-ignore
        return this._authPassService.reset(resetRequest).pipe(
            // @ts-ignore
            switchMap(response =>
                // Return a new observable with the response
                of(response),
            ),
        );
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        // @ts-ignore
        const credential: LoginRequest = {
            email: credentials.email,
            password: credentials.password,
        };

        return this._accessService.login(credential).pipe(
            switchMap(response => {
                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Store the refresh token in the local storage
                this.refreshToken = response.refreshToken;

                //

                // Return a new observable with the response
                return of(response).pipe(
                    combineLatestWith(this.flagService.getFlagLists(), this.videoService.getAllVideos()),
                    map(([response, flagList, videos]) => {
                        const flags = flagList.map(flag => {
                            return {
                                id: flag.id,
                                name: flag.name,
                                allow: flag.allow,
                                allowFree: flag.allowFree,
                                users: flag.users?.map((user: any) => {
                                    return {
                                        id: user.id,
                                        email: user.email,
                                        flags: user.flags,
                                    };
                                }),
                            };
                        });
                        sessionStorage.setItem('flags', JSON.stringify(flags));
                        document.dispatchEvent(new Event('sessionStorageFlagsChange'));
                        sessionStorage.setItem('videoTooltip', JSON.stringify(videos));
                        return of(response);
                    }),
                );
            }),
            switchMap(response => {
                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = null;
                return of(response);
            }),
        );
    }

    /**
     * Refresh token
     */
    tryToRefreshToken(): Observable<any> {
        return this._accessService
            .refreshToken({
                refreshToken: this.refreshToken,
            })
            .pipe(
                catchError(() => {
                    // Return false @todo: Delete local storage and setup _authenticated
                    this._authenticated = false;
                    sessionStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    return of(false);
                }),
                switchMap((response: any) => {
                    if (response === false) {
                        this._authenticated = false;
                        sessionStorage.removeItem('accessToken');
                        localStorage.removeItem('refreshToken');
                        return of(false);
                    } else {
                        this.accessToken = response.accessToken;
                        this.refreshToken = response.refreshToken;
                        this._authenticated = true;
                        return of(true);
                    }
                }),
            );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        this._accessService.logoutUser().subscribe();

        // Remove the access token from the local storage
        sessionStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        sessionStorage.removeItem('flags');

        // Set the authenticated flag to false
        this._authenticated = false;

        this.clearLoggedUserCache(false);

        // Return the observable
        return of(true);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return this.tryToRefreshToken();
        }

        // If the access token exists and it didn't expire, sign in using it
        return of(true);
    }

    /**
     * Get the current logged in user data
     */
    get(): Observable<UserResource> {
        return this._accessService.loggedUser();
    }

    public profile(): Observable<any> {
        if (this.userSaved) {
            return of(this.userSaved);
        } else if (this.observableUser) {
            return this.observableUser;
        } else {
            this.observableUser = this._userService.user$.pipe(
                tap((user: any) => {
                    this.userSaved = user;
                    this.observableUser = null; // Reset the observable once the user is retrieved
                    if (user?.language) {
                        localStorage.setItem('language', user.language);
                    }
                }),
                shareReplay(1), // Cache the result to share with subsequent subscribers
            );
            this._appService.changePrimaryColor(this.getColorPath());
            this._appService.changePrimary2Color(this.getColor2Path());

            return this.observableUser;
        }
    }

    public refreshProfile() {
        this.observableUser = this.get().pipe(
            tap((user: any) => {
                this.userSaved = user;
                this.observableUser = null;
            }),
            shareReplay(1), // Cache the result to share with subsequent subscribers
        );
        return this.observableUser;
    }

    public clearLoggedUserCache(refresh = true) {
        this._appService.changePrimaryColor('#e84e0e');
        this._appService.changePrimary2Color('#002b4f');
        localStorage.removeItem('splashLogo');

        if (this.userSaved) {
            localStorage.setItem('language', this.userSaved.language);
        }
        this.userSaved = null;
        this.observableUser = null;
        this.kfpStripeService.resetSubscriptions();
        if (refresh) {
            this.profile();
        }
    }

    public getColorPath() {
        if (this.userSaved?.company && this.userSaved.company?.color) {
            return this.userSaved.company.color;
        } else if (this.userSaved?.color) {
            return this.userSaved.color;
        } else if (localStorage.getItem('color')) {
            return localStorage.getItem('color');
        } else {
            this._appService.changePrimaryColor('#e84e0e');
            return localStorage.getItem('color');
        }
    }

    public getColor2Path() {
        if (this.userSaved?.company && this.userSaved.company?.colorSecondary) {
            return this.userSaved.company.colorSecondary;
        } else if (this.userSaved?.colorSecondary) {
            return this.userSaved.colorSecondary;
        } else if (localStorage.getItem('color2')) {
            return localStorage.getItem('color2');
        } else {
            this._appService.changePrimary2Color('#002b4f');
            return localStorage.getItem('color2');
        }
    }

    public getLogoPath() {
        if (this.userSaved?.company) {
            if (this.userSaved.company?.imagePath) {
                localStorage.setItem('splashLogo', this.userSaved.company.imagePath);
                return this.userSaved.company.imagePath;
            }
            localStorage.removeItem('splashLogo');
            return 'assets/images/cropped-KFP_logo_online_300px.svg';
        } else if (this.userSaved?.imagePath) {
            localStorage.setItem('splashLogo', this.userSaved.imagePath);
            return this.userSaved.imagePath;
        }
        localStorage.removeItem('splashLogo');
        return 'assets/images/cropped-KFP_logo_online_300px.svg';
    }

    validatePassword(control: AbstractControl): { [key: string]: any } | null {
        const value: string = control.value;
        const hasNumber = /\d/.test(value);
        const hasUppercase = /[A-Z]/.test(value);
        const hasLowercase = /[a-z]/.test(value);
        const hasMinimumLength = value && value.length >= 8;
        let errorMessage = this.translateService.instant('section.pass.mustContain');
        let error = false;
        if (!hasNumber) {
            error = true;
            errorMessage += this.translateService.instant('section.pass.number');
        }

        if (!hasUppercase) {
            error = true;
            errorMessage += this.translateService.instant('section.pass.uppercase');
        }

        if (!hasLowercase) {
            error = true;
            errorMessage += this.translateService.instant('section.pass.lowercase');
        }

        if (!hasMinimumLength) {
            error = true;
            errorMessage += this.translateService.instant('section.pass.minimumLength');
        }

        if (error) {
            errorMessage = errorMessage.slice(0, -2);
            errorMessage += '.';
            return { invalidPassword: errorMessage };
        }

        return null;
    }

    checkPasswords(group: FormGroup) {
        if (!group.controls.password || !group.controls.passwordConfirmation) {
            return true;
        }
        const pass = group.controls.password.value;
        const confirmPass = group.controls.passwordConfirmation.value;
        group.controls['passwordConfirmation'].setErrors(
            pass === confirmPass ? group.controls['passwordConfirmation'].errors : { notSame: true },
        );
        return pass === confirmPass ? null : { notSame: true };
    }

    public activeProfile(): Observable<any> {
        if (this.profile) {
            return of(this.profile);
        } else if (this.observableUser) {
            return this.observableUser;
        } else {
            this.observableUser = this._userService.user$.pipe(
                map((user: any) => {
                    this.profile = user;
                    //this.profileChanged.emit(this.profile);
                    return this.profile;
                }),
            );
            return this.observableUser;
        }
    }

    signUp(user: any): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._accessService.registerUser(user).pipe(
            // @ts-ignore
            switchMap(response =>
                // Return a new observable with the response
                of(response),
            ),
        );
    }

    get currency(): string {
        return this.userSaved && this.userSaved?.currency ? this.userSaved?.currency.replace('EUR', '€') : 'Kč';
    }

    get language(): string {
        return this.userSaved?.language === 'cz' ? 'cs' : this.userSaved?.language || 'cs';
    }

    setActualCalcDate() {
        this.calcService.getLastRecordForFinData().subscribe(date => {
            const formattedDate = moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
            if (moment(formattedDate).isValid()) {
                localStorage.setItem('lastDate', formattedDate.toString());
            } else {
                localStorage.setItem('lastDate', '2023-03-31'); // def. fallback
            }

            this._historicalParametersService.parameters.value.startYear = moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD')
                .subtract(20, 'years')
                .year();
            this._historicalParametersService.parameters.value.startMonth =
                moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years').month() + 1;
            this._probabilityParametersService.parameters.value.startYear = moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD')
                .subtract(20, 'years')
                .year();
            this._probabilityParametersService.parameters.value.startMonth =
                moment(localStorage.getItem('lastDate'), 'YYYY-MM-DD').subtract(20, 'years').month() + 1;
        });
    }

    getFlags() {
        return this.flagService.getFlagLists().pipe(
            map(flagList => {
                const flags = flagList.map(flag => ({
                    id: flag.id,
                    name: flag.name,
                    allow: flag.allow,
                    allowFree: flag.allowFree,
                    users: flag.users?.map((user: any) => ({
                        id: user.id,
                        email: user.email,
                        flags: user.flags,
                    })),
                }));
                sessionStorage.setItem('flags', JSON.stringify(flags));
                document.dispatchEvent(new Event('sessionStorageFlagsChange'));
                return flags;
            }),
        );
    }

    checkActualCalcDate() {
        if (!moment(localStorage.getItem('lastDate'))?.isValid()) {
            this.setActualCalcDate();
        }
    }
}

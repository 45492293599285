import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fuseAnimations } from 'fuseui';
import { FuseAlertType } from 'fuseui';
import { AuthService } from '../auth.service';
import { TranslateService } from '@ngx-translate/core';
import { HelpModalService } from '../../utils/video-dialog/help-modal.service';
import { of, switchMap, take } from 'rxjs';
import { UserService } from '../../users/users.service';
import { UserResource, VideoResource, VideoService } from '../../api';
import { KfpStripeService } from '../../utils/stripe/kfpstripe.service';

@Component({
    selector: 'auth-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class AuthSignInComponent implements OnInit {
    @ViewChild('signInNgForm') signInNgForm!: NgForm;

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: '',
    };

    signInForm!: FormGroup;
    showAlert = false;

    constructor(
        private _authService: AuthService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private userService: UserService,
        private translateService: TranslateService,
        private helpModal: HelpModalService,
        private kfpStripeService: KfpStripeService,
        private readonly videoService: VideoService,
    ) {
        if (this._authService.refreshToken) {
            this._router.navigate(['unlock-session']);
        }
    }

    ngOnInit(): void {
        // Create the form
        this.signInForm = this._formBuilder.group({
            email: ['', [Validators.required]],
            password: ['', Validators.required],
            rememberMe: [''],
        });
    }

    signIn(): void {
        // Return if the form is invalid
        if (this.signInForm.invalid) {
            return;
        }

        // Disable the form
        this.signInForm.disable();

        // Hide the alert
        this.showAlert = false;

        // Sign in

        this._authService
            .signIn(this.signInForm.value)
            .pipe(
                switchMap((data) => {
                    this._authService.setActualCalcDate();

                    setTimeout(() => {
                        this.helpModal.openHelp('common.first', true);
                    }, 1000);
                    return this.userService.user$;
                }),
                take(1),
            )
            .subscribe({
                next: (user: UserResource) => {
                    if (user.language) {
                        localStorage.setItem('language', user.language);
                    }

                    //Load Stripe and Subscriptions
                    this.kfpStripeService.loadStripe();

                    const redirectURL = '/signed-in-redirect';
                    // Navigate to the redirect url
                    this._router.navigateByUrl(redirectURL);
                },
                error: (response) => {
                    // Re-enable the form
                    this.signInForm.enable();

                    // Reset the form
                    this.signInNgForm.resetForm();

                    if (response?.error.meta) {
                        this.alert = {
                            type: 'error',
                            message: response?.error?.meta?.message,
                        };
                    } else {
                        // Set the alert
                        this.alert = {
                            type: 'error',
                            message:
                                this.translateService.instant('auth.signup.error') +
                                ': ' +
                                this.translateService.instant(response?.error?.message),
                        };
                    }

                    // Show the alert
                    this.showAlert = true;
                },
            });
    }
}

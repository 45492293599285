import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, catchError, combineLatest, filter, map, of, switchMap, take } from 'rxjs';
import { UserService } from '../../../../../libs/kfp/src/lib/users/users.service';
import { KfpStripeService } from '../../../../../libs/kfp/src/lib/utils/stripe/kfpstripe.service';
import { FlagsService } from '../../../../../libs/kfp/src/lib/utils/flags/flags.service';

@Injectable({
    providedIn: 'root',
})
export class ActiveSubscriptionGuard implements CanActivate {
    constructor(
        private readonly userService: UserService,
        private readonly router: Router,
        private readonly kfpStripeService: KfpStripeService,
        private readonly flagsService: FlagsService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.userService.user$.pipe(
            take(1),
            switchMap(user => {
                if (!user) {
                    // User is not logged in, redirect to login
                    this.router.navigate(['/login']);
                    return of(false);
                } else {
                    // User is logged in, check subscription
                    return this.anySubscriptionForProductType(route.routeConfig.path).pipe(
                        map(hasSubscription => {
                            if (!hasSubscription) {
                                // No valid subscription, redirect to dashboard
                                this.router.navigate(['/dashboard']);
                                return false;
                            }
                            return true;
                        }),
                    );
                }
            }),
            catchError(error => {
                console.error('canActivate subscription calc error!', route.routeConfig.path, error);
                this.router.navigate(['/404-not-found']);
                return of(false);
            }),
        );
    }

    private anySubscriptionForProductType(type: string): Observable<boolean> {
        const subscriptionsLoaded$ = this.kfpStripeService.subscriptionsLoaded$.pipe(
            filter(loaded => loaded),
            take(1),
        );

        const historicalLicence$ = combineLatest([
            this.kfpStripeService.activeHistoricalSubscription$,
            this.kfpStripeService.activeUserHistoricalLicenceDate$,
            this.kfpStripeService.companyHistoricalLicence$,
            this.kfpStripeService.activeStandardSubscription$,
            this.kfpStripeService.companyLicence$,
            this.kfpStripeService.activeUserLicenceDate$,
        ]).pipe(
            take(1),
            map(
                ([
                    activeHistoricalSubscription,
                    companyHistoricalLicence,
                    activeUserHistoricalLicenceDate,
                    activeStandardSubscription,
                    companyLicence,
                    activeUserLicenceDate,
                ]) => {
                    return (
                        (activeHistoricalSubscription !== null && activeHistoricalSubscription !== undefined) ||
                        (companyHistoricalLicence !== null && companyHistoricalLicence !== undefined) ||
                        (activeUserHistoricalLicenceDate !== null && activeUserHistoricalLicenceDate !== undefined) ||
                        (activeStandardSubscription !== null && activeStandardSubscription !== undefined) ||
                        (companyLicence !== null && companyLicence !== undefined) ||
                        (activeUserLicenceDate !== null && activeUserLicenceDate !== undefined)
                    );
                },
            ),
        );
        const probabiltyLicence$ = combineLatest([
            this.kfpStripeService.activeProbabilitySubscription$,
            this.kfpStripeService.activeUserProbabilityLicenceDate$,
            this.kfpStripeService.companyProbabilityLicence$,
            this.kfpStripeService.activeStandardSubscription$,
            this.kfpStripeService.companyLicence$,
            this.kfpStripeService.activeUserLicenceDate$,
        ]).pipe(
            take(1),
            map(
                ([
                    activeProbabilitySubscription,
                    companyProbabilityLicence,
                    activeUserProbabilityLicenceDate,
                    activeStandardSubscription,
                    companyLicence,
                    activeUserLicenceDate,
                ]) => {
                    return (
                        (activeProbabilitySubscription !== null && activeProbabilitySubscription !== undefined) ||
                        (companyProbabilityLicence !== null && companyProbabilityLicence !== undefined) ||
                        (activeUserProbabilityLicenceDate !== null && activeUserProbabilityLicenceDate !== undefined) ||
                        (activeStandardSubscription !== null && activeStandardSubscription !== undefined) ||
                        (companyLicence !== null && companyLicence !== undefined) ||
                        (activeUserLicenceDate !== null && activeUserLicenceDate !== undefined)
                    );
                },
            ),
        );

        const finMathLicence$ = combineLatest([
            this.kfpStripeService.activeFinMathSubscription$,
            this.kfpStripeService.activeUserFinMathLicenceDate$,
            this.kfpStripeService.companyFinMathLicence$,
            this.kfpStripeService.activeStandardSubscription$,
            this.kfpStripeService.companyLicence$,
            this.kfpStripeService.activeUserLicenceDate$,
        ]).pipe(
            take(1),
            map(
                ([
                    activeFinMathSubscription,
                    companyFinMathLicence,
                    activeUserFinMathLicenceDate,
                    activeStandardSubscription,
                    companyLicence,
                    activeUserLicenceDate,
                ]) => {
                    return (
                        (activeFinMathSubscription !== null && activeFinMathSubscription !== undefined) ||
                        (companyFinMathLicence !== null && companyFinMathLicence !== undefined) ||
                        (activeUserFinMathLicenceDate !== null && activeUserFinMathLicenceDate !== undefined) ||
                        (activeStandardSubscription !== null && activeStandardSubscription !== undefined) ||
                        (companyLicence !== null && companyLicence !== undefined) ||
                        (activeUserLicenceDate !== null && activeUserLicenceDate !== undefined)
                    );
                },
            ),
        );

        return subscriptionsLoaded$.pipe(
            switchMap(() => {
                switch (type) {
                    case 'fin_math':
                        return finMathLicence$;

                    case 'historical':
                        return historicalLicence$;

                    case 'probability': {
                        return probabiltyLicence$;
                    }
                    case 'profit': {
                        return combineLatest([
                            this.kfpStripeService.activeRentPropertySubscription$,
                            this.kfpStripeService.activeUserRentPropertyLicenceDate$,
                            this.kfpStripeService.companyRentPropertyLicence$,
                        ]).pipe(
                            take(1),
                            map(([activeRentPropertySubscription, companyRentPropertyLicence, activeUserRentPropertyLicenceDate]) => {
                                return (
                                    (activeRentPropertySubscription !== null && activeRentPropertySubscription !== undefined) ||
                                    (companyRentPropertyLicence !== null && companyRentPropertyLicence !== undefined) ||
                                    (activeUserRentPropertyLicenceDate !== null && activeUserRentPropertyLicenceDate !== undefined) ||
                                    this.flagsService.hasUserFreeAccess('ff_real_estate_profit')
                                );
                            }),
                        );
                    }
                    case 'mortgage': {
                        return combineLatest([
                            this.kfpStripeService.activeMortgageInvestSubscription$,
                            this.kfpStripeService.activeUserMortgageInvestLicenceDate$,
                            this.kfpStripeService.companyMortgageInvestLicence$,
                        ]).pipe(
                            take(1),
                            map(([activeMortgageInvestSubscription, companyMortgageInvestLicence, activeUserMortgageInvestLicenceDate]) => {
                                return (
                                    (activeMortgageInvestSubscription !== null && activeMortgageInvestSubscription !== undefined) ||
                                    (companyMortgageInvestLicence !== null && companyMortgageInvestLicence !== undefined) ||
                                    (activeUserMortgageInvestLicenceDate !== null && activeUserMortgageInvestLicenceDate !== undefined) ||
                                    this.flagsService.hasUserFreeAccess('ff_mortgage_and_investment')
                                );
                            }),
                        );
                    }
                    case 'calculations': {
                        return combineLatest([
                            this.kfpStripeService.hasUserAnySubsOrLicence(),
                            of(this.flagsService.hasUserAnyFreeAccess()),
                        ]).pipe(
                            take(1),
                            map(([anySubs, anyFreeAccess]) => {
                                return anySubs || anyFreeAccess;
                            }),
                        );
                    }
                    default:
                        return of(false); // Default case if type does not match any known subscription type
                }
            }),
        );
    }
}

<div class="relative flex flex-col w-full h-full">

    <!-- Dismiss button -->
    <ng-container>
        <div class="absolute top-1 right-1">
            <button
                mat-icon-button
                [matDialogClose]="undefined">
                <mat-icon
                    class="text-secondary"
                    [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>
    </ng-container>

    <!-- Content -->
    <div class="flex flex-col items-center p-8 pb-4">

        <div *ngIf="data.icon.show">
            <div
                class="flex flex-0 items-center justify-center w-10 h-10 mt-8 rounded-full">
                <svg-icon src="assets/icons/kfpicons/excla_triangle.svg" class="icon-size-10 text-kfpred"></svg-icon>
            </div>

        </div>

        <div *ngIf="data.title">
            <div
                class="flex flex-col items-center text-primary2">
                <ng-container *ngIf="data.title">
                    <div
                        class="text-3xl leading-8 font-bold mt-3 text-primary2"
                        [innerHTML]="data.title"></div>
                </ng-container>
            </div>
        </div>

        <div *ngIf="data.message">
            <div
                class="flex flex-col items-center text-center text-primary2 text-lg">
                <ng-container *ngIf="data.message">
                    <div
                        class="text-primary2 mt-1 leading-6"
                        [innerHTML]="data.message"></div>
                </ng-container>
            </div>
        </div>

    </div>

    <!-- Actions -->
    <ng-container *ngIf="data.actions.confirm.show || data.actions.cancel.show">
        <div
            class="flex items-center justify-center px-6 py-4">

            <div class="bg-white hover:bg-slate-100 inline-block mr-3 border-solid border-black border
                        cursor-pointer text-black px-3 py-[6px] rounded-lg ease-in-out duration-500 whitespace-nowrap"
                 mat-stroked-button [matDialogClose]="'cancelled'">
                <span>{{data.actions.cancel.label}}</span>
            </div>

            <div class="bg-primary inline-block ml-3 hover:shadow-xl shadow-primary
                        cursor-pointer text-white px-3 py-[6px] rounded-lg ease-in-out duration-500 whitespace-nowrap"
                 mat-stroked-button [matDialogClose]="'confirmed'">
                <span>{{data.actions.confirm.label}}</span>
            </div>

        </div>
    </ng-container>

</div>

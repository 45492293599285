import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CreateHistoryDataRequest, CreateRealEstateDataRequest} from 'kfp';
import moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep} from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class EstatesParametersService {
    constructor(private readonly translateService: TranslateService) {
        this.translateService
            .stream([
                'estates.calculation.chart.commission',
                'estates.calculation.chart.initial_invest',
                'estates.calculation.chart.hypo_fee',

                'estates.calculation.chart.repair_fund',
                'estates.calculation.chart.maintenance_reserve',
                'estates.calculation.chart.income_tax',
                'estates.calculation.chart.insurance',
            ])
            .subscribe((keys) => {
                const parameters = this.parameters.value;

                if (parameters) {
                    parameters.oneTimeCosts[0].name = keys['estates.calculation.chart.commission'];
                    parameters.oneTimeCosts[1].name = keys['estates.calculation.chart.initial_invest'];
                    parameters.oneTimeCosts[2].name = keys['estates.calculation.chart.hypo_fee'];

                    parameters.monthlyCosts[0].name = keys['estates.calculation.chart.repair_fund'];
                    parameters.monthlyCosts[1].name = keys['estates.calculation.chart.maintenance_reserve'];
                    parameters.monthlyCosts[2].name = keys['estates.calculation.chart.income_tax'];
                    parameters.monthlyCosts[3].name = keys['estates.calculation.chart.insurance'];


                }
                this.parameters.next(parameters);
            });
    }

    public parameters: BehaviorSubject<CreateRealEstateDataRequest> = new BehaviorSubject<CreateRealEstateDataRequest>({
        discountRate: 0.04,
        targetYear: 30,
        mortgage: {
            dueDate: 30,
            rate: 0.049,
            ownResource: 2000000,
            loanValue: 4000000,
            monthlyPayFlagged: 10000,
            flag: 'monthlyPay'
        } as any,
        realEstate: {
            marketPrice: 6000000,
            priceRise: 0.03,
            purchasePrice: 6000000,
        },
        rentalIncome: {
            monthlyRent: 25000,
            rentRise: 0.03,
            occupancy: 1,
        },
        oneTimeCosts: [
            {
                name: this.translateService.instant('estates.calculation.chart.commission'),
                value: 180000,
                year: 0,
                active: true,
            },
            {
                name: this.translateService.instant('estates.calculation.chart.initial_invest'),
                value: 100000,
                year: 0,
                active: true
            },
            {
                name: this.translateService.instant('estates.calculation.chart.hypo_fee'),
                value: 2000,
                year: 0,
                active: true
            },
        ],
        monthlyCosts: [
            {
                name: this.translateService.instant('estates.calculation.chart.repair_fund'),
                value: 4000,
                rate: 0.03,
                active: true
            },
            {
                name: this.translateService.instant('estates.calculation.chart.maintenance_reserve'),
                value: 2000,
                rate: 0.03,
                active: true
            },
            {
                name: this.translateService.instant('estates.calculation.chart.income_tax'),
                value: 500,
                rate: 0.03,
                active: true
            },
            {
                name: this.translateService.instant('estates.calculation.chart.insurance'),
                value: 500,
                rate: 0.03,
                active: true
            },
        ],
    });
    sharedParams$: Observable<CreateRealEstateDataRequest> = this.parameters.asObservable();

    private _selectedYear: any = 0;

    private selectedYearSubject: BehaviorSubject<any> = new BehaviorSubject(this._selectedYear);
    selectedYear$: Observable<any> = this.selectedYearSubject.asObservable();

    private _result = 0;
    private resultSubject: BehaviorSubject<any> = new BehaviorSubject(this._result);
    result$: Observable<any> = this.resultSubject.asObservable();

    getResult(): any {
        return this._result;
    }

    setResult(value: any) {

        this._result = value;
        this.resultSubject.next(this.getResult());
    }

    set selectedYear(value: any) {
        this._selectedYear = value;
        this.selectedYearSubject.next(value);
    }

    getParameters(): CreateRealEstateDataRequest {
        return this.parameters.value;
    }

    setParameters(data: CreateRealEstateDataRequest): void {

        // Check each parameter for NaN or undefined
        const invalidParams = Object.entries(data.mortgage)
            .filter(([key, value]) => {
                if (((typeof value === 'number' && isNaN(value)) || (value === undefined)) && !key.endsWith('Flagged')) {
                    return true;
                }
                return false;
            })
            .map(([key]) => key);

        // If there are invalid parameters, don't apply the new values
        if (invalidParams.length > 0) {
            console.error('invalidParams present ', invalidParams);
            return;
        }
        // Apply new values if all parameters are valid
        this.parameters.next(data);
    }

    public addNotice(mode: string, notice: string) {
        const parameters: any = this.getParameters();
        parameters[`${mode}_notice`] = notice;
        this.setParameters(parameters);
    }

    public getNotice(mode: string) {
        const parameters: any = this.getParameters();
        return parameters[`${mode.toLowerCase()}_notice`];
    }

    resetParameters(): void {
        this._selectedYear = 10;
        this.selectedYearSubject.next(this._selectedYear);

        const defaultParams: CreateRealEstateDataRequest = {
            discountRate: 0.04,
            targetYear: 30,
            mortgage: {
                dueDate: 30,
                rate: 0.049,
                ownResource: 2000000,
                loanValue: 4000000,
                monthlyPayFlagged: 10000,
                flag: 'monthlyPay'
            } as any,
            realEstate: {
                marketPrice: 6000000,
                priceRise: 0.03,
                purchasePrice: 6000000,
            },
            rentalIncome: {
                monthlyRent: 25000,
                rentRise: 0.03,
                occupancy: 1,
            },
            oneTimeCosts: [
                {
                    name: this.translateService.instant('estates.calculation.chart.commission'),
                    value: 180000,
                    year: 0,
                    active: true,
                },
                {
                    name: this.translateService.instant('estates.calculation.chart.initial_invest'),
                    value: 100000,
                    year: 0,
                    active: true
                },
                {
                    name: this.translateService.instant('estates.calculation.chart.hypo_fee'),
                    value: 2000,
                    year: 0,
                    active: true
                },
            ],
            monthlyCosts: [
                {
                    name: this.translateService.instant('estates.calculation.chart.repair_fund'),
                    value: 4000,
                    rate: 0.03,
                    active: true
                },
                {
                    name: this.translateService.instant('estates.calculation.chart.maintenance_reserve'),
                    value: 3000,
                    rate: 0.03,
                    active: true
                },
                {
                    name: this.translateService.instant('estates.calculation.chart.income_tax'),
                    value: 500,
                    rate: 0.03,
                    active: true
                },
                {
                    name: this.translateService.instant('estates.calculation.chart.insurance'),
                    value: 500,
                    rate: 0.03,
                    active: true
                },
            ],
        };
        this.parameters.next(defaultParams);
    }
}
